<template>
  <div>
    <!-- section 1 -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex justify-space-around" style="width: 75%; padding: 80px 0px 160px 0px">
        <div class="dot-div" style="width: 40%; padding: 60px">
          <div style="background-color: white; height: 100%" class="px-12 py-12">
            <div class="pb-0" style="font-size: 44px; font-weight: 700">
              AI 바우처
            </div>
            <div class="pb-10" style="font-size: 36px; color: #4c4c4c">
              70% 할인 받으세요
            </div>
            <div class="pb-10" style="font-size: 18px; font-weight: 400">
              AI 바우처 지원사업은 정보통신 산업진흥원 주관으로 AI 기술 적용이 필요한 <strong>수요기업에게 바우처를 지원</strong>하여 공급기업의 <strong>AI솔루션을 활용 및 제공받을 수 있도록 지원</strong>하는 제도입니다.
            </div>
            <div class="d-flex flex-column">
              <v-btn color="#367BF5" x-large dark class="mb-6" @click="goContact()">
                바로 상담 신청하기
              </v-btn>
            </div>
          </div>
        </div>
        <div style="width: 40%" class="d-flex align-center">
          <img src="@/assets/img/ai_voucher1.png" style="width: 100%; height: auto">
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex justify-space-around" style="width: 90%; padding: 100px 0px 160px 0px">
        <div class="dot-div" style="width: 40%; padding: 60px">
          <div style="background-color: white; height: 100%" class="px-12 py-12">
            <div class="pb-0" style="font-size: 44px; font-weight: 700">
              AI 바우처
            </div>
            <div class="pb-10" style="font-size: 36px; color: #4c4c4c">
              70% 할인 받으세요
            </div>
            <div class="pb-10" style="font-size: 16px; font-weight: 400">
              AI 바우처 지원사업은 정보통신 산업진흥원 주관으로 AI 기술 적용이 필요한 <strong>수요기업에게 바우처를 지원</strong>하여 공급기업의 <strong>AI솔루션을 활용 및 제공받을 수 있도록 지원</strong>하는 제도입니다.
            </div>
            <div class="d-flex flex-column">
              <v-btn color="#367BF5" x-large dark class="mb-6" @click="goContact()">
                바로 상담 신청하기
              </v-btn>
            </div>
          </div>
        </div>
        <div style="width: 40%">
          <img src="@/assets/img/ai_voucher1.png" style="width: 100%; height: auto">
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center" style="width: 80%; padding: 0px 0px 30px 0px">
        <div class="dot-div" style="width: 100%; padding: 20px">
          <div style="background-color: white; height: 100%" class="px-3 py-3 text-center">
            <div class="pb-0" style="font-size: 24px; font-weight: 700">
              AI 바우처
            </div>
            <div class="pb-6" style="font-size: 20px; color: #4c4c4c">
              70% 할인 받으세요
            </div>
            <div class="pb-6" style="font-size: 16px; font-weight: 400">
              AI 바우처 지원사업은 정보통신 산업진흥원 주관으로 AI 기술 적용이 필요한 <strong>수요기업에게 바우처를 지원</strong>하여 공급기업의 <strong>AI솔루션을 활용 및 제공받을 수 있도록 지원</strong>하는 제도입니다.
            </div>
            <div class="d-flex flex-column">
              <v-btn color="#367BF5" dark class="mb-6" @click="goContact()">
                바로 상담 신청하기
              </v-btn>
            </div>
          </div>
        </div>
        <div style="width: 100%; padding-top: 20px">
          <img src="@/assets/img/ai_voucher1.png" style="width: 100%; height: auto">
        </div>
      </div>
    </div>

    <!-- section 2 -->
    <div class="d-flex align-start justify-center" style="background-color: #F9FAFC">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex flex-column align-center" style="width: 70%; padding: 160px 0px 160px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 90px">
          기업이 당면한 문제를 해결해드립니다.
        </div>
        <div class="d-flex align-center justify-space-between ml-auto" style="width: 90%">
          <div>
            <div class="d-flex align-center mb-12">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher3.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                인공지능 전문인력 부족
              </div>
            </div>
            <div class="d-flex align-center mb-12">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher4.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                AI 기술 도입 비용 부담
              </div>
            </div>
            <div class="d-flex align-center">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher5.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                업무 효율성 및 생산성 저하
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/img/arrow.png" style="width: 100%; height: auto">
          </div>
          <div>
            <div class="d-flex flex-column align-center justify-center mx-auto" style="border: 1px solid gainsboro; border-radius: 10px; background-color: white">
              <div style="width: 100%; padding: 48px 36px">
                <img src="@/assets/img/ai_voucher2.png" style="width: 100%; height: auto">
              </div>
              <div class="px-9 py-4" style="background-color: #BBDEFB; width: 100%; border-radius: 0px 0px 10px 10px">
                <div class="text-center" style="font-size: 30px; font-weight: 700">
                  비용 부담 없는 AI 솔루션 구축
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column align-center" style="width: 80%; padding: 160px 0px 160px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 90px">
          기업이 당면한 문제를 해결해드립니다.
        </div>
        <div class="d-flex align-center justify-space-between ml-auto" style="width: 90%">
          <div>
            <div class="d-flex align-center mb-12">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher3.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                인공지능 전문인력 부족
              </div>
            </div>
            <div class="d-flex align-center mb-12">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher4.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                AI 기술 도입 비용 부담
              </div>
            </div>
            <div class="d-flex align-center">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher5.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 24px; font-weight: 500" class="pl-6">
                업무 효율성 및 생산성 저하
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/img/arrow.png" style="width: 90%; height: auto">
          </div>
          <div class="d-flex flex-column align-center justify-center" style="border: 1px solid gainsboro; border-radius: 10px; background-color: white">
            <div style="width: 100%; padding: 48px 36px">
              <img src="@/assets/img/ai_voucher2.png" style="width: 100%; height: auto">
            </div>
            <div class="px-9 py-4" style="background-color: #BBDEFB; width: 100%; border-radius: 0px 0px 10px 10px">
              <div class="text-center" style="font-size: 30px; font-weight: 700">
                비용 부담 없는 AI 솔루션 구축
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center" style="width: 90%; padding: 60px 0px 100px 0px">
        <div style="font-size: 24px; font-weight: 700; padding-bottom: 30px" class="text-center">
          기업이 당면한 문제를 해결해드립니다.
        </div>
        <div class="d-flex flex-column align-center justify-space-between" style="width: 80%">
          <div>
            <div class="d-flex align-center mb-2">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher3.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 16px; font-weight: 500" class="pl-6">
                인공지능 전문인력 부족
              </div>
            </div>
            <div class="d-flex align-center mb-2">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher4.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 16px; font-weight: 500" class="pl-6">
                AI 기술 도입 비용 부담
              </div>
            </div>
            <div class="d-flex align-center">
              <div style="width: 20%">
                <img src="@/assets/img/ai_voucher5.png" style="width: 100%; height: auto">
              </div>
              <div style="font-size: 16px; font-weight: 500" class="pl-6">
                업무 효율성 및 생산성 저하
              </div>
            </div>
          </div>
          <div class="text-center my-8" style="height: 100px">
            <img src="@/assets/img/arrow.png" style="width: 50%; height: auto; transform: rotate(90deg) translateX(20px)">
          </div>
          <div class="d-flex flex-column align-center justify-center" style="border: 1px solid gainsboro; border-radius: 10px; background-color: white">
            <div style="width: 100%; padding: 24px">
              <img src="@/assets/img/ai_voucher2.png" style="width: 100%; height: auto">
            </div>
            <div class="px-4 py-4" style="background-color: #BBDEFB; width: 100%; border-radius: 0px 0px 10px 10px">
              <div class="text-center" style="font-size: 18px; font-weight: 700">
                비용 부담 없는 AI 솔루션 구축
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- section 3 -->
    <div class="d-flex align-start justify-center">
      <!-- 웹 (1920*1080 이상) -->
      <div class="d-none d-xl-flex flex-column align-center" style="width: 80%; padding: 160px 0px 160px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 80px">
          지원대상 및 지원금액
        </div>
        <div class="d-flex align-center justify-center" style="padding-bottom: 120px">
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8" style="border-radius: 5px; width: 400px; height: 400px">
            <div style="font-size: 24px; font-weight: 700">지원 대상</div>
            <img src="@/assets/img/ai_voucher6.png" style="width: 50%; height: auto">
            <div style="font-size: 18px; font-weight: 500" class="text-center">
              기업의 당면 문제를 해결하고 생산성 혁신을 위해 AI 제품·서비스 적용이 필요한 <strong>중소·벤처/중견기업</strong>
            </div>
          </div>
          <div style="width: 28px"></div>
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8" style="border-radius: 5px; width: 400px; height: 400px">
            <div style="font-size: 24px; font-weight: 700">지원 금액</div>
            <div class="d-flex align-center">
              <div style="font-size: 18px; font-weight: 500">기업 당 최대</div>
              <div style="width: 6px"></div>
              <div style="font-size: 48px; font-weight: 700">3억원</div>
            </div>
            <div style="height: 40px"></div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div style="font-size: 36px; font-weight: 700" class="mr-6">
            코그콤 AI 바우처에 대해 궁금한 점이 있다면 언제든지 문의하세요!
          </div>
          <v-btn color="#367BF5" large dark @click="goContact()">문의하기</v-btn>
        </div>
      </div>
      <!-- 웹 (1920*1080 미만) -->
      <div class="d-none d-xl-none d-sm-flex flex-column align-center" style="width: 80%; padding: 160px 0px 160px 0px">
        <div style="font-size: 48px; font-weight: 700; padding-bottom: 80px">
          지원대상 및 지원금액
        </div>
        <div class="d-flex align-center justify-center" style="padding-bottom: 120px">
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8" style="border-radius: 5px; width: 400px; height: 400px">
            <div style="font-size: 24px; font-weight: 700">지원 대상</div>
            <img src="@/assets/img/ai_voucher6.png" style="width: 50%; height: auto">
            <div style="font-size: 18px; font-weight: 500" class="text-center">
              기업의 당면 문제를 해결하고 생산성 혁신을 위해 AI 제품·서비스 적용이 필요한 <strong>중소·벤처/중견기업</strong>
            </div>
          </div>
          <div style="width: 28px"></div>
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8" style="border-radius: 5px; width: 400px; height: 400px">
            <div style="font-size: 24px; font-weight: 700">지원 금액</div>
            <div class="d-flex align-center">
              <div style="font-size: 18px; font-weight: 500">기업 당 최대</div>
              <div style="width: 6px"></div>
              <div style="font-size: 48px; font-weight: 700">3억원</div>
            </div>
            <div style="height: 40px"></div>
          </div>
        </div>
        <div class="d-flex align-center">
          <div style="font-size: 36px; font-weight: 700" class="mr-6">
            코그콤 AI 바우처에 대해 궁금한 점이 있다면 언제든지 문의하세요!
          </div>
          <v-btn color="#367BF5" large dark @click="goContact()">문의하기</v-btn>
        </div>
      </div>
      <!-- 모바일 -->
      <div class="d-sm-none d-flex flex-column align-center justify-center" style="width: 100%; padding: 60px 0px 60px 0px">
        <div style="font-size: 24px; font-weight: 700; padding-bottom: 60px">
          지원대상 및 지원금액
        </div>
        <div class="d-flex flex-column align-center justify-center" style="padding-bottom: 90px">
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8 mb-12" style="border-radius: 5px; width: 80vw; min-height: 80vw">
            <div style="font-size: 24px; font-weight: 700">지원 대상</div>
            <img src="@/assets/img/ai_voucher6.png" style="width: 50%; height: auto">
            <div style="font-size: 18px; font-weight: 500" class="text-center">
              기업의 당면 문제를 해결하고 생산성 혁신을 위해 AI 제품·서비스 적용이 필요한 <strong>중소·벤처/중견기업</strong>
            </div>
          </div>
          <div class="shadow-box d-flex flex-column justify-space-between align-center px-12 py-8" style="border-radius: 5px; width: 80vw; height: 80vw">
            <div style="font-size: 24px; font-weight: 700">지원 금액</div>
            <div class="d-flex align-center">
              <div style="font-size: 18px; font-weight: 500">기업 당 최대</div>
              <div style="width: 6px"></div>
              <div style="font-size: 48px; font-weight: 700">3억원</div>
            </div>
            <div style="height: 40px"></div>
          </div>
        </div>
        <div class="d-flex flex-column align-center">
          <div style="font-size: 20px; font-weight: 600; width: 80%" class="text-center mb-6">
            코그콤 AI 바우처에 대해 궁금한 점이 있다면 언제든지 문의하세요!
          </div>
          <v-btn color="#367BF5" dark @click="goContact()">문의하기</v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "AiVoucher",
  methods: {
    goContact() {
      this.$router.push('/contact').catch(() => {})
      scrollTo(0, 0)
    },
    goNipa(){
      window.open('https://www.nipa.kr/')
    },
    clickInquire(){
      window.open('https://docs.google.com/forms/d/e/1FAIpQLScz1Y1SgY5P-ID5eZlwx3ON0fvZwIdDTycjabPCNIYJJXUkbA/viewform')
    }
  }
}
</script>

<style scoped>
.dot-div {
  background: url('../assets/img/dot_pattern.png');
  background-size: cover;
}
.shadow-box {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0,0,0, 0.07), 0 1px 5px 0 rgba(0,0,0, 0.07) !important;
}
</style>